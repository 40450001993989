const { rehydrate, plugins } = require('glamor');

function getSpecificityPlugin(id) {
  return function({ selector, style }) {
    selector = selector
      .split(',')
      .map(sel => `#${id} ${sel.trim()}`)
      .join(', ');
    return { selector, style };
  };
}

plugins.add(getSpecificityPlugin('___gatsby'));

const getTargetOffset = hash => {
  const id = window.decodeURI(hash.replace(`#`, ``));
  if (id !== ``) {
    const element = document.getElementById(id);
    if (element) {
      console.log(element);
      return element.getBoundingClientRect().top;
    }
  }
  return null;
};

exports.onClientEntry = () => {
  if (window._glamor) {
    rehydrate(window._glamor);
  }
};

// exports.onInitialClientRender = () => {
//   requestAnimationFrame(() => {
//     const offset = getTargetOffset(window.location.hash);
//     if (offset !== null) {
//       window.scrollTo(0, offset);
//     }
//   });
// };

// exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
//   return location.hash.length === 0;
// };
